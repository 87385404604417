const EMAIL_REGEXP =
  /((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"))@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i; // eslint-disable-line no-control-regex

const WORDS_REGEXP = /\b(\w)/g;

const CAPITALS_REGEXP = /([A-Z])/g;

export function matchEmail(st: string): RegExpMatchArray | null {
  return st.match(EMAIL_REGEXP);
}

export function matchCapitals(st: string): RegExpMatchArray | null {
  return st.match(CAPITALS_REGEXP);
}

export function matchWords(st: string): RegExpMatchArray | null {
  return st.match(WORDS_REGEXP);
}

export function emailToInitials(st: string): string {
  let initials = '';
  const mailMatch = matchEmail(st);
  if (mailMatch) {
    const splits: string[] = mailMatch[1].split('.', 2);
    if (splits.length > 1) {
      initials = splits.map((value) => value.charAt(0)).join('');
    } else if (splits.length) {
      initials = splits[0].substring(0, 2);
    }
  }
  return initials;
}

export function wordsToInitials(st: string): string {
  let initials = '';
  if (st.indexOf(' ') > -1) {
    // split by spaces
    const matches = matchWords(st);
    if (matches && matches.length > 1) {
      initials = matches[0] + matches[1];
    }
  }
  return initials;
}

export function capitalsToInitials(st: string): string {
  let initials = '';
  // split by Capitalized letters
  const matches = matchCapitals(st);
  if (matches && matches.length > 1) {
    initials = matches[0] + matches[1];
  }
  return initials;
}
