import { ReactiveController } from 'lit';
import jwtDecode from 'jwt-decode';
import { Entitlement, IDToken } from './EntiltlementController.interfaces';
import { CoreAppframeTopbar } from './CoreAppframeTopbar';
import Config from './config/config';

/**
 * Class responsible for checking JWT validity and validate related entitlements.
 */
export class EntitlementController implements ReactiveController {
  static readonly MINUTES_BEFORE_TOKEN_RENEWAL = 5;
  host: CoreAppframeTopbar;
  tokenPayload: string | undefined;
  checked = false;

  constructor(host: CoreAppframeTopbar) {
    (this.host = host).addController(this);
  }

  /**
   * Ensure some validity check before calling services.
   * @private
   */
  private _checks(): void {
    if (!this.host.productName) {
      throw new Error('ProductName must be provided, review your configuration.');
    }
  }

  /**
   * Check the validity of the JWT token, renew it if necessary and getEntitltments, and ensure
   * the connected user is entitled to the main component produt name property.
   * @private
   */
  private async _checkSSOToken(): Promise<boolean> {
    this._checks();

    // init token
    this.tokenPayload = await this._init();
    console.log(this.tokenPayload);

    // if token in its  5 last validity minutes, renew it
    if (this._isRenewTokenNecessary()) {
      this.tokenPayload = await this._renew();
    }

    // check entitlements base on api response and product name provided though web component property.
    this.checked = await this.getEntitlements();
    return this.checked;
  }

  /**
   * Gets JWT token from SSO service, user must have been authentified through SSO login form.
   * @private
   */
  private async _init(): Promise<string> {
    return await this._callSSOEndpoint('auth/ssotoken');
  }

  /**
   * Renew token if needed, should be executed when being in the grace period validity of the initial JWT token.
   * @private
   */
  private async _renew(): Promise<string> {
    return await this._callSSOEndpoint('auth/renewtoken', this.tokenPayload);
  }

  /**
   * Common method to handle calls to SSO Api.
   * @param endpoint specific needed endpoint to call.
   * @param bearer if provided, an Authorization header is added to the request headers to ensure proper communication with SSO service.
   * @private
   */
  private async _callSSOEndpoint(endpoint: string, bearer?: string): Promise<string> {
    const response = await fetch(
      `${this.host.ssoEndpoint ? this.host.ssoEndpoint : Config.endpoints.ssoServiceEndpoint}/${endpoint}`,
      {
        headers: this._getAuthHeaders(bearer),
        credentials: 'include',
      },
    );
    if (!response.ok) {
      throw new Error(
        `An error occurs while reaching sso endpoint with status ${response.status}, please login first.`,
      );
    }

    // parse result and return id token
    const result = await response.json();
    return result.id_token;
  }

  /**
   * Common method to handle calls to UAM Api.
   * @param endpoint specific needed endpoint to call.
   * @param bearer an Authorization header is added to the request headers to ensure proper communication with UAM service.
   * @private
   */
  private async _callUAMEndpoint(endpoint: string, bearer: string): Promise<unknown[]> {
    const response = await fetch(
      `${this.host.uamEndpoint ? this.host.uamEndpoint : Config.endpoints.uamServiceEndpoint}/${endpoint}`,
      {
        headers: this._getAuthHeaders(bearer),
        credentials: 'include',
      },
    );
    if (!response.ok) {
      throw new Error(`An error occurs while reaching UAM endpoint with status ${response.status}.`);
    }

    const result = await response.json();
    return result;
  }

  /**
   * If current JWT token is in its last 5 validity minutes, return true, if jwt  not exit, return true.
   * @private
   */
  private _isRenewTokenNecessary(): boolean {
    return this.tokenPayload
      ? ((jwtDecode<IDToken>(this.tokenPayload).exp || 0) * 1000 - Date.now()) / 60000 <
          EntitlementController.MINUTES_BEFORE_TOKEN_RENEWAL
      : true;
  }

  /**
   * Create specific headers for RFS endpoints try to identify current application.
   * @param bearer if provided, an Authorization header is added to the request headers to ensure authorization.
   * @private
   */
  private _getAuthHeaders(bearer?: string): HeadersInit {
    let headers: HeadersInit = this.host.productName ? { 'x-moodys-app': this.host.productName } : {};
    if (bearer) {
      headers = { ...headers, Authorization: 'Bearer ' + bearer };
    }
    return headers;
  }

  /**
   * Get entitlements related to the currently logged user from UAM service.
   */
  async getEntitlements(): Promise<boolean> {
    const result: Entitlement[] = (await this._callUAMEndpoint('entitlements', this.tokenPayload!)) as Entitlement[];
    if (result) {
      return (
        result.find((value) => {
          return value.name == this.host.productName;
        }) != null
      );
    }
    return false;
  }

  /**
   * When all given requiredEntitlements are found in the list of fetched product entitlements, then return true, else false.
   * @param requiredEntitlements names of entitlements that are required
   * @param match specify match type
   */

  hostUpdated(): void {
    if (!this.host.disableEntitlementCheck && !this.tokenPayload) {
      this._checkSSOToken().then((value) => this.host.requestUpdate());
    }
  }
}
