
// Do not modify this file by hand!
// Re-generate this file by running lit-localize


import { str } from '@lit/localize';

/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const templates = {
  's352719d86112de9a': `Aider`,
  's5be3c6d61cd9182f': `Notifications`,
  's6ba50bb0842ba1e2': `Applications`,
  'sa03807e44737a915': `Se déconnecter`,
  'sa48f81f001b893d2': `Utilisateur`,
  'sdd998e7324ae4083': `Paramètres utilisateur`,
  'sd1cae0dcd2945c35': `Nous contacter`,
  'saf63d34c8601dd41': str`${0}`,
};
