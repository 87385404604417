export function checkIfImageExists(url: string): Promise<boolean> {
  const img = new Image();
  img.src = url;

  const promise: Promise<boolean> = new Promise(function (resolve, reject) {
    img.addEventListener('complete', () => {
      return resolve(true);
    });
    img.addEventListener('load', () => {
      return resolve(true);
    });
    img.addEventListener('error', (err) => {
      return reject(false);
    });
  });
  return promise;
}
