export const localCssConfig =
  `
    /* fallback */
    @font-face {
      font-family: 'Material Icons';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/materialicons/v115/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
    }
    /* fallback */
    @font-face {
      font-family: 'Material Icons Outlined';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v90/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
    }

    .parentContainer {
      display: none; 
      background-color: white;
      position: absolute;
      top: 40px;
      right: 0px;
      z-index: 10;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Bliss Pro', 'Roboto', 'Helvetica Neue', Sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      max-width:956px;
      max-height: 575px;
      float: right;
      overflow: auto;
      padding: 0px; 
    }

    .subContainer {
      display: flex; 
      flex-direction: row; 
      flex-wrap: wrap; 
      min-height:40px; 
      max-height: 80px; 
      align-items: center; 
      column-gap:20px;
    }

    .warp {
      height: 40px;  
      box-sizing: border-box; 
      padding: 8px 0px 8px 15px; 
      cursor: pointer; 
      width: 280px;
    }

    .icon{
      width: 24px; 
      height: 24px;
    }

    .text {
      font-size: 16px; 
      font-weight: 400; 
      color: #000000; 
      position: relative; 
      bottom: 5px; 
      left: 5px
    }

    hr {
      border:0; 
      border-top: 1px 
      solid #e0e0e0; 
      margin: 0;
    }

    .appWarp {
      display: flex; 
      flex-direction: row; 
      flex-wrap: wrap; 
      margin:15px;
    }

    .app {
      width: 280px; 
      height: auto; 
      margin-bottom: 10px;
    }

    .subApp {
      flex-direction: row; 
      display: flex; 
      flex-wrap: wrap; 
      margin-bottom: 10px; 
      padding-top: 24px;
    }

    .appName {
      margin: 4px 0px 0px 8px; 
      font-size: 16px;
    }

    .envName {
      color: #045AC4; 
      margin: 0px 0px 8px 32px; 
      cursor: pointer;
    }

    .envText {
      text-decoration: none; 
      font-size: 14px
    }

    .product-selector-footer {
      float: right;  
      height: 40px;
    }

    .exploreProducts {
      padding-right: 12px; 
      line-height: 40px;
    }

    .exploreProductsText {
      margin: 0px 0px 0px 12px; 
      color:#707070; 
      cursor: pointer; 
      font-size:14px
    }

    .exploreProductsText:hover {
      color: #045AC4;
    }

    .userDropdownContainer {
      display: none;
      background-color: white;
      position: absolute;
      top: 40px;
      right: 0px;
      z-index: 10;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      justify-content: left;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      min-width:150px;
      max-height: 575px;
      float: right;
      overflow: auto;
      cursor: pointer;
      padding: 0px 0px 0px 0px;
    }

    .optionWarp{
      display: flex; 
      padding: 16px 16px 16px 16px;
      background-color:white;
    }

    .optionWarp:hover {
      background-color:rgba(0, 0, 0, 0.04); 
    }

    .chatContainer {
      display: none;
      position: absolute;
      bottom: 48px;
      right: 40px;
      width: 742px;
      height: calc(100% - 110px);
      z-index: 10;
      overflow: auto;
      // background-color: lightblue;
      // border: 8px solid orange;
    }
    
    `;