export default {
  endpoints: {
    ssoServiceEndpoint: 'https://sso.moodysanalytics.com/sso-api',
    uamServiceEndpoint: 'https://sso.moodysanalytics.com/uam',
    webComponentEndpoint: 'https://webcomponents.moodysanalytics.com/core-appframe-topbar/latest',
    webComponentBaseUrl: 'https://webcomponents.moodysanalytics.com',
    bankingPortal: 'https://banking.moodysanalytics.com',
    heapNumber: '2283047351',
    environmentName: 'PROD',
    ssoEntitlement: 'https://sso.moodysanalytics.com',
    appsPortalUrl: 'https://banking.moodysanalytics.com/my-applications',
    staticContent: 'https://webcomponents.moodysanalytics.com/images',
    exploreProductsEndpoint: 'https://www.moodysanalytics.com/solutions-overview',
    drawerGetWay: 'https://www.moodys.com/mfe/mdc-gateway-drawer/build/mdc-gateway-drawer.js',
    productAssistant: 'https://webcomponents.moodysanalytics.com/chat-element/latest/ChatElement.js'
  }
};
