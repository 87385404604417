import { CSSResultGroup, html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { until } from 'lit/directives/until.js';
import { styleMap } from 'lit/directives/style-map.js';
import { customElement, property, state } from 'lit/decorators.js';
import { configureLocalization, LocaleModule, localized, msg, str } from '@lit/localize';
import { PropertyValues } from '@lit/reactive-element';
import { EntitlementController } from './EntitlementController';
import styles from './styles/CoreAppframeTopbar.scss';
import { localCssConfig } from './localCss';
import { sourceLocale, targetLocales } from './LocaleConfiguration';
import {
  ButtonDefinition, DefaultButtonDefinition, DefaultButtons, EVENT_NAMES, TopBarConfiguration,
} from './CoreAppframeTopbar.interface';
import { TemplateResult } from 'lit-html';
import { capitalsToInitials, emailToInitials, wordsToInitials } from './utils/Matchers';
import { checkIfImageExists } from './utils/FileChecker';
import { stringToHslColorInRange } from './utils/Converter';
import * as templates_fr from './i18n/fr';
import Config from './config/config';
declare var heap: any;
/**
 * @slot default - Only one slot available, provided content will be added on the left hand-side of the button bar (LTR direction)
 *
 * @cssprop {Color} --core-appframe-topbar-text-color Control the text color, default to #FFFFFF.
 * @cssprop {Color} --core-appframe-topbar-bg-color Background color of the topbar, default to #001966.
 */
@localized()
@customElement('core-appframe-topbar')
export class CoreAppframeTopbar extends LitElement {
  /**
   * @private
   */
  private static DefaultButtonsDefinition: DefaultButtons<DefaultButtonDefinition> = {
    chat: {
      name: 'chat',
      classNames: 'material-icons',
      iconNames: 'auto_fix_high',
      visible: false,
      position: 0,
      eventName: EVENT_NAMES.CHAT,
      tooltip: msg('Product navigator')
    },
    notifications: {
      name: 'notifications',
      classNames: 'material-icons',
      iconNames: 'notifications_none',
      visible: true,
      position: 1,
      eventName: EVENT_NAMES.NOTIFICATIONS,
      tooltip: msg('Notifications')
    },
    help: {
      name: 'help',
      classNames: 'material-icons',
      iconNames: 'help_outline',
      visible: true,
      position: 2,
      eventName: EVENT_NAMES.HELP,
      tooltip: msg('Help')
    },
    apps: {
      name: 'apps',
      classNames: 'material-icons',
      iconNames: 'apps',
      visible: false,
      position: 3,
      eventName: EVENT_NAMES.APPS,
      tooltip: msg('Applications')
    },
    user: {
      name: 'user',
      classNames: 'material-icons-outlined',
      iconNames: 'account_circle',
      visible: false,
      position: 4,
      eventName: EVENT_NAMES.USER,
      tooltip: msg('User')
    },
  };
  static styles: CSSResultGroup = styles;
  static SCRIPT_FILENAME = 'core-moodys-injected-style';
  static MOODYS_COMPONENT_IMAGE_FOLDER = 'https://webcomponents.moodysanalytics.com/images';
  localCSS
  gateWayScript;
  chatScript;
  chatElement;
  chatContainer;
  dropDownOpened = false;
  userDropDownOpened = false;
  topBarElement: any;
  dropDownElement: any;
  userDropDownElement: any;
  toHost: any;
  username: any;
  response = { status: undefined, text: undefined };
  decodedToken;
  clickIconEvent;
  siteName;
  /**
   * Application Name should match the RAFA entitlement name, @required.
   */
  @property({ type: String, attribute: 'product-name' }) productName = '';
  /**
   * Product's Label to display in header bar, @required.
   *
   */
  @property({ type: String, attribute: 'product-label' }) productLabel = 'Missing Product Label';
  @property({ type: String }) caption = '';
  @property({ type: String }) token;
  @property({ type: Array })
  apps = [];
  @property({ type: String, attribute: 'logo-image-src' }) logoImageSrc = '';
  @property({ type: Object }) configuration;
  @property({ type: Boolean, attribute: 'display-username' }) displayUserName = false;
  /**
   * URL endpoint to reach RAFA SSO API, used to check that the currently logged user is authenticated.
   */
  @property({ type: String, attribute: 'sso-endpoint' }) ssoEndpoint = '';
  /**
   * URL endpoint to reach RAFA UAM API, used to check that the currently logged user is entitled
   * for 'product-name'.
   */
  @property({ type: String, attribute: 'uam-endpoint' }) uamEndpoint = '';
  /**
   * If true, disabled check for the product entitlement. Important note, if you plan to set this
   * attribute to True, only add the attribute on the html like this (the attribute will be interpreted as true,
   * default value is false): <core-appframe-topbar disable-entitlement-check>
   */
  @property({ type: Boolean, attribute: 'disable-entitlement-check' }) disableEntitlementCheck = true;
  /**
   * User Image URL or bytearray to display as user avatar, a loading spinner will be displayed while loading this iamge.
   */
  @property({ type: String, attribute: 'user-image-src' }) userImageSrc: string | null = '';
  @state()
  private imgSrc: Promise<TemplateResult<1>> | null = null;
  /**
   * Controller dedicated to api authentication/authorization calls. Used to ensure that currently logged user is entitled to the provided product name.
   * @private
   */
  private entitlementController: EntitlementController | undefined;
  /**
   * Default constructor, initialize the component and inject needed font for icons support into Host application.
   */

  constructor() {
    super();
    CoreAppframeTopbar._injectFont();
    this.entitlementController = new EntitlementController(this);
    this.toHost = new CustomEvent('toHost', {
      'detail': {}
    });
    this.loadGateWayScript();
  }

  loadGateWayScript() {
    this.gateWayScript = document.createElement('script');
    this.gateWayScript.type = 'module';
    this.gateWayScript.src = Config.endpoints.drawerGetWay;
    document.head.appendChild(this.gateWayScript);
    this.gateWayScript.onload = () => { };
  }

  loadChatScript() {
    if ((window['ChatElement']) == undefined) {
      this.chatScript = document.createElement('script');
      this.chatScript.src = Config.endpoints.productAssistant;
      document.head.appendChild(this.chatScript);
      this.chatScript.onload = () => {
        this.addChatElementToDom();
      };
    } else {
      this.appendNewChatConfigs();
    }
  }

  addChatElementToDom() {
    var anchor = document.createElement('div');
    const clone = this.topBarElement.shadowRoot.querySelector('.chatContainer').cloneNode(true);
    anchor.appendChild(clone);
    this.topBarElement.parentNode!.appendChild(anchor);
    this.chatElement = document.createElement('chat-element');
    this.chatContainer = document.querySelector('.chatContainer');
    setTimeout(() => {
      this.chatContainer.appendChild(this.chatElement);
      this.chatElement.setAttribute('token', this.token);
      this.chatElement.setAttribute('config', JSON.stringify({
        service: this.configuration?.chat?.service,
        product: this.configuration?.chat?.product,
        appContext: this.configuration?.chat?.appContext,
        preFilter: this.configuration?.chat?.preFilter,
        noFullscreen: this.configuration?.chat?.noFullscreen,
        nonCloseable: this.configuration?.chat?.nonCloseable
      })
      );
    }, 100);
    document.addEventListener('mouseup', (e: any) => {
      var chatIcon = this.topBarElement.shadowRoot.getElementById('chat');
      if (!e.composedPath().includes(this.chatContainer) && this.chatContainer.style.display == 'block' && !e.composedPath().includes(chatIcon)) {
        this.manageChatState();
      } else {
      }
    })
  }

  manageChatState() {
    if (this.chatElement !== undefined && this.chatElement !== null) {
      if (this.chatContainer.style.display === 'none') {
        this.chatContainer.style.display = 'block';
      } else {
        this.chatContainer.style.display = 'none';
      }
    } else {
      console.log(`The chat element didn't load yet. Please try again in a few moments`);
    }
  }

  appendNewChatConfigs() {
    this.chatElement.setAttribute('config', JSON.stringify({
      service: this.configuration?.chat?.service,
      product: this.configuration?.chat?.product,
      appContext: this.configuration?.chat?.appContext,
      preFilter: this.configuration?.chat?.preFilter,
      noFullscreen: this.configuration?.chat?.noFullscreen,
      nonCloseable: this.configuration?.chat?.nonCloseable
    }));
  }

  prepareDataToDrawer(arg) {
    const arrayToDrawer = [];
    arg.forEach(element => {
      if (element.environments.length > 1) {
        element.environments.forEach(env => {
          let obj = {
            name: element.displayName,
            subtitle: env.env_name,
            iconUrl: Config.endpoints.staticContent + '/product-icons/' + element.productImage,
            url: null
          }
          obj.url = env.web_link;
          arrayToDrawer.push(obj);
        });
      } else {
        let obj = {
          name: element.displayName,
          iconUrl: Config.endpoints.staticContent + '/product-icons/' + element.productImage,
          url: element.environments[0].web_link
        }
        arrayToDrawer.push(obj);
      }
    });
    const bankingPortalProducts = {
      order: 1,
      url: Config.endpoints.bankingPortal,
      name: 'Banking Portal',
      initials: 'BP',
      tooltip: 'My banking applications',
      iconUrl: null,
      isParent: false,
      children: arrayToDrawer
    };
    let el = this.shadowRoot.getElementById('myDrawer');
    el.setAttribute('customproducts', JSON.stringify([bankingPortalProducts]));
    el.setAttribute('userid', this.decodedToken.email);
  }

  /**
   * As a webcomponent can enforce download of a font by its own, we ensure the application container will do it for us.
   */
  private static _injectFont(): void {
    const localCSS = localCssConfig;
    // check for injected fonts from other moodys components, do it only one time
    if (!document.getElementById(CoreAppframeTopbar.SCRIPT_FILENAME)) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.id = CoreAppframeTopbar.SCRIPT_FILENAME;
      style.innerText = localCSS;
      head.appendChild(style);
    }
  }
  /**
   * Template to generate additional buttons.
   * @private
   */

  private _applyDefaultButtons(): unknown {
    const def = {
      name: 'user',
      classNames: 'material-icons-outlined',
      iconNames: 'account_circle',
      visible: true,
      position: 4,
      eventName: EVENT_NAMES.USER,
      tooltip: msg('User')
    }
    const isUserButton = def.name == 'user';
    return html`
    ${repeat(this._getDefaultButtonsConfig(),
      (item) => this._buttonTemplate(item, false))}
      <div
      id="${def.name}"
      @keypress="${(event: Event) => this._clickIcon(event, def)}"
      @click="${(event: Event) => this._clickIcon(event, def)}"
      class="${this.displayUserName && isUserButton ? 'with-username' : ''}"
      >
      ${!isUserButton ? html`${this._iconTemplate(def, false)}` : this._userAvatarTemplate(def, false)}
    </div>
    <gateway-drawer id="myDrawer" customProductsTitle="Banking" iconColor="#FFF"></gateway-drawer>
    `;
  }

  private _applyAdditionalButtons(): unknown {
    return this.configuration?.customButtons?.length
      ? html`${repeat(this.configuration.customButtons, (item) => this._buttonTemplate(item, true))}`
      : '';
  }

  /**
   * Template to generate default buttons.
   * @private
   */
  /**
   * Build model configuration for buttons generation, use a default definition and override with configuration provided to the components
   * @private
   */
  private _getDefaultButtonsConfig(): ButtonDefinition[] {
    // apply overrides
    const conf: ButtonDefinition[] = Object.entries({
      help: {
        ...CoreAppframeTopbar.DefaultButtonsDefinition.help,
        ...this.configuration?.defaultButtons?.help,
      },
      notifications: {
        ...CoreAppframeTopbar.DefaultButtonsDefinition.notifications,
        ...this.configuration?.defaultButtons?.notifications,
      },
      apps: {
        ...CoreAppframeTopbar.DefaultButtonsDefinition.apps,
        ...this.configuration?.defaultButtons?.apps,
      },
      user: {
        ...CoreAppframeTopbar.DefaultButtonsDefinition.user,
        ...this.configuration?.defaultButtons?.user,
      },
      chat: {
        ...CoreAppframeTopbar.DefaultButtonsDefinition.chat,
        ...this.configuration?.defaultButtons?.chat,
      }
    }).map((vals) => vals[1]);
    // filter on visibility + re-order on position
    return conf.filter((button) => button.visible).sort((a, b) => (a.position || 0) - (b.position || 0));
  }

  /**
   * Lit Lifecycle hook execute once after properties have been set. Used to check only once the presence of a product image in the Moodys 'CDN' image library.
   * Precedence to display logo in the topbar will as follow:
   * - logo source url has been set, so retrived image from this location.
   * - any image in moodys common image CDN Url exist for this product name (component property).
   * - lastly generate a logo from product name string content @see _initials method to get how incon is built).
   * @param _changedProperties
   * @protected
   */
  protected firstUpdated(_changedProperties?: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    const p: Promise<string | boolean> = this.logoImageSrc
      ? Promise.resolve(this.logoImageSrc)
      : checkIfImageExists(`${CoreAppframeTopbar.MOODYS_COMPONENT_IMAGE_FOLDER}/${this.productName}.svg`);
    this.imgSrc = p
      .then((value) => {
        return html`<img src='${typeof value == 'string'
          ? value
          : CoreAppframeTopbar.MOODYS_COMPONENT_IMAGE_FOLDER + '/' + this.productName + '.svg'
          }'></img>`;
      })
      .catch((reason) => {
        return this._productIconTemplate();
      });

    const localThis = this;
    document.addEventListener('fromHost', function (e: any) {
      if (localThis.toHost.detail.renewToken) {
        localThis.token = e.detail.token;
        console.log('Fresh token detected, refiring the last call that failed due to token expiration');
        localThis.decodeToken(localThis.token);
        localThis.handleTokenExpiration();
      }
    });
    this.toHost = new CustomEvent('toHost', {
      'detail': {}
    });

    document.addEventListener('mouseup', function (e: any) {
      if (localThis.clickIconEvent && localThis.clickIconEvent.target != e.target) {
        if (localThis.dropDownElement && !localThis.dropDownElement!.contains(<Element>e.target)) {
          localThis.dropDownElement.remove();
          localThis.dropDownOpened = false;
        }
        if (localThis.userDropDownElement && !localThis.userDropDownElement!.contains(<Element>e.target)) {
          localThis.closeUserDropdown();
        } else {
          localThis.executeEvent(localThis.clickIconEvent, e.toElement.innerText);
          setTimeout(() => {
            localThis.closeUserDropdown();
          }, 100);
        }
      }
    });
  }

  updated(changedProperties: PropertyValues) {
    this.topBarElement = document.querySelector('core-appframe-topbar')!;
    if (changedProperties.has('token')) {
      console.log('Top bar element detected a token in ' + Config.endpoints.environmentName);
      this.decodeToken(this.token);
      this.getAppConfigurations();
      this.getSiteName();
    }
    if (changedProperties.has('configuration') && this.configuration?.chat) {
      this.loadChatScript();
    }
  }

  getSiteName() {
    const urlParams = new URLSearchParams(window.location.search);
    const siteIdParam = urlParams.get('orgSiteId');
    if (siteIdParam !== null) {
      fetch(`${Config.endpoints.ssoEntitlement}/entitlement/v2/tenant/sites`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.token
        },
      })
        .then((response) => {
          this.response.status = response.status;
          this.response.text = 'Cannot getSiteName';
          if (!response.ok) { }
          if (response.status == 401) {
            this.toHost.detail.renewToken = true;
            this.toHost.detail.method = 'getSiteName';
            document.dispatchEvent(this.toHost);
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (this.response.status !== 401) {
            const found = data.find((element) => element.orgSiteId == siteIdParam);
            this.siteName = found.siteType;
            this.requestUpdate();
          } else {
            console.log('Top Bar element token expired');
          }
        })
    }
  }

  decodeToken(token: any) {
    this.decodedToken = JSON.parse(window.atob(token.split('.')[1]));
    this.username = this.decodedToken.email;
    (window as any).moodys_variables = {
      loginUserId: this.decodedToken.email,
      organizationId: this.decodedToken.organization,
      organizationName: this.decodedToken.org_name,
      sfdcUserId: this.decodedToken.contactid,
      firstName: this.decodedToken.firstName,
      lastName: this.decodedToken.lastName

    };
    if (window['heap'] == undefined) {
      this.loadHeapScript(this.decodedToken);
    }
    this.loadQualtricsScript();
  }

  loadQualtricsScript() {
    const cyrb53 = (str, seed = 0) => {
      let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
      for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
      h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
      h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    };
    let Quuid = cyrb53(window['moodys_variables'].loginUserId.toLowerCase(), 3);
    if (typeof window['QSI'] === "undefined") {
      window['QSI'] = {};
      window['QSI'].config = {
        externalReference: Quuid,
        contactEmbeddedData: { "user_id": window['moodys_variables'].loginUserId.toLowerCase() },
        firstName: window['moodys_variables'].firstName,
        lastName: window['moodys_variables'].lastName
      };
    }
    (function () {
      var g = function (e, h, f, g) {
        this.get = function (a) { for (var a: any = a + "=", c = document.cookie.split(";"), b = 0, e = c.length; b < e; b++) { for (var d = c[b]; " " == d.charAt(0);)d = d.substring(1, d.length); if (0 == d.indexOf(a)) return d.substring(a.length, d.length) } return null };
        this.set = function (a, c) {
          var b = new Date; b.setTime(b.getTime() + 6048E5);
          var b2 = b.toString();
          b2 = "; expires=" + b2.toString(); document.cookie = a + "=" + c + b + "; path=/; "
        };
        this.check = function () { var a = this.get(f); if (a) a = a.split(":"); else if (100 != e) "v" == h && (e = Math.random() >= e / 100 ? 0 : 100), a = [h, e, 0], this.set(f, a.join(":")); else return !0; var c = a[1]; if (100 == c) return !0; switch (a[0]) { case "v": return !1; case "r": return c = a[2] % Math.floor(100 / c), a[2]++, this.set(f, a.join(":")), !c }return !0 };
        this.go = function () { if (this.check()) { var a = document.createElement("script"); a.type = "text/javascript"; a.src = g; document.body && document.body.appendChild(a) } };
        this.start = function () { var t = this; "complete" !== document.readyState ? window.addEventListener ? window.addEventListener("load", function () { t.go() }, !1) : (<any>window).attachEvent && (<any>window).attachEvent("onload", function () { t.go() }) : t.go() };
      };
      try { (new g(100, "r", "QSI_S_ZN_1zudwq9420zfVtQ", "https://zn1zudwq9420zfvtq-moodysanalytics.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_1zudwq9420zfVtQ")).start() } catch (i) { }
    })();

    var body = document.querySelector("body");
    var qualtrics = document.createElement("div");
    qualtrics.setAttribute("id", "ZN_1zudwq9420zfVtQ");
    body.appendChild(qualtrics);
  }

  loadHeapScript(decodedToken) {
    window['heap'] = window['heap'] || []
    heap.load = function (appId, heapConfig) {
      window['heap'].appid = appId;
      window['heap'].config = heapConfig || {};
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://cdn.heapanalytics.com/js/heap-" + appId + ".js";
      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);
      const cloneArray = (arrayLike) => Array.prototype.slice.call(arrayLike, 0);
      const createMethod = function (method) {
        return function () {
          heap.push([
            method,
            ...cloneArray(arguments)
          ]);
        }
      };
      const methods = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
      ];
      for (let method of methods) {
        heap[method] = createMethod(method)
      }
    };
    heap.load(Config.endpoints.heapNumber);
    heap.identify(decodedToken.email);
    heap.addUserProperties({
      'Organization ID': decodedToken.organization,
      'Organization Name': decodedToken.org_name,
      'SFDC User ID': decodedToken.contactid
    });
  }

  getAppConfigurations() {
    fetch(`${Config.endpoints.ssoEntitlement}/entitlement/v2/infra/environment`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    })
      .then((response) => {
        this.response.status = response.status;
        this.response.text = 'Cannot getAppConfigurations';
        if (!response.ok) { }
        if (response.status == 401) {
          this.toHost.detail.renewToken = true;
          this.toHost.detail.method = 'getAppConfigurations';
          document.dispatchEvent(this.toHost);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (this.response.status !== 401) {
          this.generateAppObject(data.applications);
        } else {
          console.log('Top Bar element token expired');
        }
      })
  }

  generateAppObject(arg) {
    this.apps = [];
    arg.forEach(application => {
      let obj = {
        name: '',
        displayName: '',
        documentationUrl: '',
        productImage: '',
        environments: []
      }
      if (application.environments.length > 0) {
        obj.name = application.app_name;
        obj.displayName = application.ui_configs.displayName;
        obj.productImage = application.ui_configs.productImage;
        obj.documentationUrl = application.ui_configs.documentationUrl;
        obj.environments = application.environments;
        this.apps.push(obj);
      }
    });
    this.prepareDataToDrawer(this.apps);
    // Logic to dynamically add vertical line between each column
    for (let i = 0; i < this.apps.length; i++) {
      if (this.apps.length > 1 && this.apps.length % 3 !== 0) {
        this.apps.push({ environments: [] });
      }
    }
    // Remove vertical lines if less than 3 apps
    this.apps = this.apps.filter(el => el.name !== undefined);
  }

  handleTokenExpiration() {
    if (this.toHost?.detail?.method) {
      this[this.toHost.detail.method];
      this.toHost.detail.renewToken = false;
      this.toHost.detail.method = null;
    }
  }
  /**
   * @inheritDoc
   * @protected
   */
  protected render(): unknown {
    return html`${this.disableEntitlementCheck || this.entitlementController?.checked
      ? this._mainTemplate()
      : html` <header>Unauthorized</header>`}`;
  }
  /**
   * Main Template for the component, holds all the rendering logic.
   * @private
   */
  private _mainTemplate(): TemplateResult<1> {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
        rel="stylesheet"/>
      <header>
        ${until(this.imgSrc, html` <div class="empty"></div>`)}
        <div class="title">
          <span style="font-size:13px;">${this.caption}</span>
          <span style="font-size:20px;">${this.productLabel}</span>
        </div>
          <div style=${this.siteName ? 'display: block' : 'display: none;'} class="siteName">
          ${this.siteName}
          <span class="tooltiptext">${this.productLabel} ${Config.endpoints.environmentName}
          <br>
          (${this.siteName})
          </span>
        </div>
      </header>
      <div class="button-stack">
        <slot></slot>
        ${this._applyAdditionalButtons()} 
        ${this._applyDefaultButtons()}
      </div>
          <!-- Old app selector -->
          <div class="parentContainer" style="display: none;">
            <div class="subContainer">
                <div class="warp"  onclick="window.open('${Config.endpoints.bankingPortal}', '_blank');">
                  <img class="icon" src="${Config.endpoints.staticContent}/product-icons/Banking-Portal.svg" />
                  <span class="text">Banking Portal</span>
                </div>
                <div class="warp" onclick="window.open('${Config.endpoints.bankingPortal}/benchmark', '_blank');">
                <img class="icon" src="${Config.endpoints.staticContent}/product-icons/Insights _placeholder_logo.svg" />
                <span class="text">Insights</span>
                </div>
             </div>
            <hr>
            <div class="appWarp">
              ${this.apps.map((app, i) =>
      html`
                    <div class="app">
                      <div class="subApp">
                        <img style=${app.productImage ? 'width: 24px; height: 24px;' : 'display: none;'} src="${Config.endpoints.staticContent}/product-icons/${app.productImage}" />
                        <div class="appName">${app.displayName}</div>
                      </div>
                      ${app.environments.map((env) =>
        html`
                            <div class="envName">
                            <a target="_blank" href="${env.web_link}" class="envText">${env.env_name.slice(0, 36)}</a>
                            </div>
                        `
      )}
                    </div>
                    <div style=${this.apps.length === 1 || ((i + 1) % 3 == 0) ? 'display: none;' : 'display: block; width: 1px; border-right: 1px solid #E0E0E0; margin: 0 16px;'}></div>
                `
    )}
            </div>
            <hr>
            <div class="footer">
              <div class="exploreProducts" onclick="window.open('${Config.endpoints.exploreProductsEndpoint}', '_blank');">
              <span class="exploreProductsText">Explore more Moody's Analytics products</span>
            </div>
              </div>
          </div>
        <!-- User Dropdown -->
        <div
        class="userDropdownContainer"
        style="display: none;">
          ${this.configuration?.userDropDownOptions?.map((option, i) =>
      html`
            <span class="optionWarp">${option.name}</span>
            `
    )}
          <div style=${this.decodedToken && this.decodedToken.federatedAccount ? 'display: none' : 'display: block'}>
            <span onclick="window.open('${Config.endpoints.bankingPortal}/account', '_self');"
            class="optionWarp">${msg('Change password')}</span>
          </div>
          <span onclick="window.open('https://www.moodysanalytics.com/Contact-Us', '_blank');" class="optionWarp">${msg('Contact us')}</span>
          <a style="text-decoration: none;"
          href="${Config.endpoints.bankingPortal}/logout?redirectUrl=${encodeURI(Config.endpoints.bankingPortal)}" 
          class="optionWarp">${msg('Logout')}</a>
        </div>
        <!-- Chat -->
        <div class="chatContainer" style="display: none;"></div>
        `;
  }

  /**
   * Template for the product icon (if no image sources have been retrieved).
   * @private
   */
  private _productIconTemplate(): TemplateResult<1> {
    return html`
      <div class='logo-icon'>
      ${this._initials(this.productLabel)}
      </div>
      `;
  }

  /**
   * Template for a button.
   * @param def Definition that affect rendering of the button
   * @param custom true for specific case when tooltip value is provided, replace i18n label.
   * @private
   */
  private _buttonTemplate(
    def: { name?: string; iconNames?: string; classNames?: string; tooltip?: string },
    custom: boolean,
  ): unknown {
    const isUserButton = def.name == 'user';
    return html` 
    <div
      id="${def.name}"
      @keypress="${(event: Event) => this._clickIcon(event, def)}"
      @click="${(event: Event) => this._clickIcon(event, def)}"
      class="${this.displayUserName && isUserButton ? 'with-username' : ''}"
    >
      ${!isUserButton ? html`${this._iconTemplate(def, custom)}` : this._userAvatarTemplate(def, custom)}
    </div>
    `;
  }

  /**
   * Template for the user avatar. Can be composed of an image representing the user, using an avatar based on the user's initials.
   * Username can be displayed alongside the avatar if displayUsername is set to true.
   */
  private _userAvatarTemplate(
    def: { name?: string; iconNames?: string; classNames?: string; tooltip?: string },
    custom: boolean,
  ) {
    const initials = !this.userImageSrc ? this._initials(this.username) : '';
    return html`${this.userImageSrc
      ? html` <img
          class="user spinner"
          loading="lazy"
          .src="${this.userImageSrc || ''}"
          aria-hidden="true"
          @load="${this._onImgLoad}"
          @error="${this._onImgError}"
          tabindex="0"
        />`
      : html`${this.username
        ? html`<span
              class="initials"
              style="${styleMap({ backgroundColor: stringToHslColorInRange(initials) })}"
            >
              ${initials}
            </span>`
        : html`${this._iconTemplate(def, custom)}`}`}
    ${this.displayUserName && this.username
        ? html`<span class="user-label">${this.username}</span>`
        : ''}`;
  }

  /**
   * Template for a button as an icon.
   * @param def Definition that affect rendering of the button
   * @param custom true for specific case when tooltip value is provided, replace i18n label.
   * @private
   */
  private _iconTemplate(def: ButtonDefinition, custom: boolean): unknown {
    return html`<i class="${def.classNames}" title="${custom ? def.tooltip : msg(def.tooltip!)}" tabindex="0">${def.iconNames}</i> `;
  }

  /**
   * Intials to compute from the provide string parameter.
   * Several Rules are applied to find proper initials.
   * @param toParse
   * @private
   */
  private _initials(toParse: string): string {
    if (!toParse) return '';

    const initials =
      emailToInitials(toParse) || wordsToInitials(toParse) || capitalsToInitials(toParse) || toParse.substring(0, 2);
    return initials.toUpperCase();
  }

  private _onImgLoad(e: Event) {
    (<HTMLImageElement>e.currentTarget!).classList.remove('spinner');
  }

  private _onImgError(e: Event) {
    (<HTMLImageElement>e.currentTarget).hidden = true;
    (<HTMLImageElement>e.currentTarget!).classList.remove('spinner');
  }

  /**
   * Dispatch event to the host app, use eventName property as event name.
   * For apps event, we do not dispatch a specific event but navigate the application to the banking portal.
   * In the future, a product selector panel will be displayed with access to relevant apps for the logged user.
   * @param event Related click or press event
   * @param def Button Definition.
   * @private
   */
  private _clickIcon(event: Event, def: ButtonDefinition) {
    this.clickIconEvent = event;
    if (def.eventName === EVENT_NAMES.APPS) {
      if (this.dropDownOpened === false) {
        this.dropDownElement = document.createElement('div');
        const clone = this.topBarElement.shadowRoot.querySelector('.parentContainer').cloneNode(true);
        clone.style.display = 'block';
        this.dropDownElement.appendChild(clone);
        this.topBarElement.parentNode!.appendChild(this.dropDownElement);
        this.dropDownOpened = true;
        if (this.userDropDownElement) {
          this.closeUserDropdown();
        }
      } else {
        this.dropDownOpened = false;
        this.dropDownElement.remove();
      }
    }
    if (def.eventName === EVENT_NAMES.USER) {
      if (this.userDropDownOpened === false) {
        this.userDropDownElement = document.createElement('div');
        const clone = this.topBarElement.shadowRoot.querySelector('.userDropdownContainer').cloneNode(true);
        clone.style.display = 'block';
        this.userDropDownElement.appendChild(clone);
        this.topBarElement.parentNode!.appendChild(this.userDropDownElement);
        this.userDropDownOpened = true;
        if (this.dropDownElement) {
          this.dropDownOpened = false;
          this.dropDownElement.remove();
        }
      } else {
        this.closeUserDropdown();
      }
    }
    if (def.eventName !== EVENT_NAMES.USER) {
      this.dispatchEvent(
        new CustomEvent(def.eventName, {
          detail: { origin: event.composedPath()[0] },
          bubbles: true,
          composed: true,
        }),
      );
    }
    if (def.eventName == EVENT_NAMES.CHAT) {
      this.manageChatState();
    }
  }

  executeEvent(event, arg) {
    if (this.configuration.userDropDownOptions) {
      let obj = this.configuration.userDropDownOptions.find(o => o.name === arg);
      if (obj) {
        this._clickIcon(event, obj);
      }
    }
  }

  closeUserDropdown() {
    if (this.userDropDownElement) {
      this.userDropDownElement.remove();
      this.userDropDownOpened = false;
    }
  }
}

const localizedTemplates = new Map([['fr', templates_fr]]);

export const { getLocale, setLocale } = configureLocalization({
  sourceLocale: sourceLocale,
  targetLocales: targetLocales,
  loadLocale: async (locale) => <LocaleModule>localizedTemplates.get(locale),
});

declare global {
  interface HTMLElementTagNameMap {
    'core-appframe-topbar': CoreAppframeTopbar;
  }
}

