/**
 * Compute a hsl color definition based on the string in parameter.
 * @param str used to compute color value.
 * @param saturation of the generated HSL color
 * @param lightness of the generated HSL color
 */
export function stringToHslColor(str: string, saturation: number, lightness: number): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h},${saturation}%,${lightness}%)`;
}

/**
 * Base acceptable colors, specified by UX team.
 */
const rangedColors = ['hsl(184,100%,27%)', 'hsl(279,92%,29%)', 'hsl(326,100%,35%)', 'hsl(225,100%,20%)'];

/**
 * Found one of the UX specified colors, based on string in parameter.
 * @param str
 */
export function stringToHslColorInRange(str: string): string {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return rangedColors[sum % rangedColors.length];
}
