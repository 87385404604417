export interface ButtonDefinition {
  classNames?: string;
  iconNames?: string;
  visible?: boolean;
  position?: number;
  tooltip?: string;
  ariaLabel?: string;
  eventName?: string;
}

export interface DefaultButtonDefinition extends ButtonDefinition {
  eventName: string;
  name: string;
}

export type NotificationButtonName = 'notifications';
export type HelpButtonName = 'help';
export type AppsButtonName = 'apps';
export type UserButtonName = 'user';
export type ChatButtonName = 'chat';

export type DefaultButtons<T> = {
  notifications?: T;
  help?: T;
  apps?: T;
  user?: T;
  chat?: T;
};

export interface TopBarConfiguration {
  chat?: any;
  customButtons?: ButtonDefinition[];
  defaultButtons?: DefaultButtons<ButtonDefinition>;
  userDropDownOptions?: any;
}

export const EVENT_NAMES = {
  NOTIFICATIONS: 'onNotification',
  HELP: 'onHelp',
  USER: 'onUser',
  APPS: 'onApps',
  CHAT: 'onChat'
};
